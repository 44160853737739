body{
    background-color: #f4ffff !important;
    color: #000 !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 16px !important;
}

a:link, a:visited, a:hover, a:active {
    color: #186464;
}

h1, h2, h3, h4, h5 {
    color: #186464 !important;
}

strong {
    color: #000000 !important;
}

h5 {
    font-weight: 600 !important;
    font-size: 1.4em !important;
}

.btn-primary {
    background-color: #186464 !important;
    border-color: #186464 !important;
    color: #fff !important;
}

.image-header {
    width: auto;
    min-height: 250px;
    max-height: 350px;
}

/*
    image-header-text
    hace referencia a un div que debe ser ubicado en la parte inferior de la imagen, con fondo degradado de transparente a blanco
    en donde se colocará el título de la sección, su posición dentro del div debe ser absoluta y estar ubicado en la parte inferior
*/

.image-header-space {
    min-height: 200px;
    max-height: 300px;
}

.image-header-text {
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(244,255,255,1) 100%);
    height: 50px;
}

.default {
    background-image: url(https://galeria.ayplatam.com/images/2024/06/14/bernd-dittrich-m0B-uK0GNAM-unsplash.jpg);
    background-size: cover;
}

.guatemala {
    background-image: url(https://images.ayplatam.com/gt/cover.jpg);
    background-size: cover;
}

.honduras {
    background-image: url(https://images.ayplatam.com/hn/cover.jpg);
    background-size: cover;
}

.panama {
    background-image: url(https://images.ayplatam.com/pa/cover.jpg);
    background-size: cover;
}

.costarica {
    background-image: url(https://images.ayplatam.com/cr/cover.jpg);
    background-size: cover;
}

.elsalvador {
    background-image: url(https://images.ayplatam.com/sv/cover.jpg);
    background-size: cover;
}

.colombia {
    background-image: url(https://images.ayplatam.com/co/cover.jpg);
    background-size: cover;
}

.belice {
    background-image: url(https://images.ayplatam.com/bz/cover.jpg);
    background-size: cover;
}

.form-label {
    color: #ffffff;
}