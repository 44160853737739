body {
  background-color: #186464;
  color: #ffF;
}

img {
  width: 100%;
  height: auto;
}

.text-justify {
  text-align: justify !important;
}

.text-justify span{
  color: #000000;
}

.text-justify strong{
  color: #000000;
  font-weight: bolder !important;
}

.text-justify a strong {
  color: #211976;
  font-weight: bolder !important;
  text-decoration: none !important;
}

.text-justify a {
  color: #211976 !important;
  text-decoration: none !important;
}

.logo_ayp {
  width: 50px;
  height: auto;
}

.navbar-dark{
  background-color: #414141 !important;
}

.navbar button{
  background: none;
  border:none;
}

.btn-logout{
  background-color: #414141 !important;
  border-color: #414141 !important;
}

.btn-primary:hover{
  background-color: #186464;
}

.btn-primary:after{
  background-color: #414141;
}

.bg-sidebar{
  background-color: #414141;
}

.modal-header, .modal-body, .modal-footer{
  background-color: #414141 !important;
}



.btn-size {
  background-color: #414141 !important;
  border: 1px solid #ffffff !important;
  height: 60px;
  width: 60px;
  box-sizing: border-box;
  padding: 10px;
}

.btn-size:hover {
  background-color: #186464;
  border-color: #186464 !important;
}

.btn-size-lg{
  font-size: 25px !important;
}

.btn-size-md{
  font-size: 18px !important;
}

.btn-size-sm{
  font-size: 14px !important;
}

.btn-vista {
  background-color: #414141 !important;
  border: 1px solid #ffffff !important;
  height: 40px;
  padding: 10px;
}

.selecionado{
  background-color: #186464 !important;
  border-color: #186464 !important;
}

.card{
  background-color: #0D4F83 !important;
  border-color: #0D4F83 !important;
}

.list-group-item{
  background-color: #338E8A !important;
  margin-bottom: 10px;
  border-radius: 10px;
  color: #fff !important;
}

.redondeado{
  border-radius: 50% !important;
}

.resultBelt{
  background-color: #0D4F83 !important;
}

.hightlight{
  background-color: #0D4F83 !important;
  color: #ffffff !important;
}

.flotante{
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 0px !important;
  border-radius: 50% !important;
  padding: 10px !important;
  z-index: 100 !important;
  font-size: 2em !important;
  background-color: #414141 !important;
}

.flotante:hover{
  background-color: #0D4F83 !important;
}

.btn-close{
  border-color: #ffffff;
}

.ql-align-justify{
  text-align: justify !important;
}

.ql-align-justify span{
  color: #000000;
}